.footer {
  z-index: 10;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  box-sizing: border-box;
  padding: 8px 40px;
  border-top: 1px solid rgba(13, 18, 66, 0.10);
  transition: height 0.3s;
}

.foot-right-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 32px;
}

.svg_share {
  fill: black;
  cursor: pointer;
}

.icon-share {
  width: 70px;
  height: 70px;
}

svg:hover .svg_share {
  fill: #496AFB;
}

@media screen and (max-width: 768px) {
  .footer {
    height: 54px;
    padding: 12px 16px;
  }

  .icon-share {
    width: 40px;
    height: 40px;
  }
}
