.tech-wrap {
  width: 100%;
  padding: 117px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  background: radial-gradient(circle at center, #000000 0%, #0d1242 100%);
}

.tech-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}

.tech-list-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
}

.tech-list-wrap img {
  width: 100%;
  max-width: 1280px;
}

.tech-mobilc-desc {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 127.273% */
  padding-top: 6.7px;
}

@media (max-width: 768px) {
  .tech-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    background: radial-gradient(circle at center, #000000 0%, #0d1242 100%);
  }

  .tech-title {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.96px;
  }

  .tech-list-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
  }

  .tech-list-wrap .svg-img {
    width: 100%;
  }

  .tech-mobilc-desc {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 14px;
    padding: 0 27.5px;
    padding-top: 6.7px;
  }
}
