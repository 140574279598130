.home-content {
  position: absolute;
  left: 50%;
  bottom: 45px;
  transform: translateX(-50%);
  color: white;
  text-align: center;
  z-index: 1;
  width: 100%;
  padding: 0 27.5px 0 27.5px;
  box-sizing: border-box;
}

.home-title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  padding-bottom: 12px;
}

.home-desc {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  text-transform: capitalize;
}

.home-arrow-wrap {
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.home-arrow-desc {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-transform: capitalize;
}

@keyframes arrowAnimation {
  0% { transform: translateY(0) scaleY(1); }
  50% { transform: translateY(8px) scaleY(0.8); }
  100% { transform: translateY(0) scaleY(1); }
}

.animatedPath {
  animation: arrowAnimation 1.5s ease-in-out infinite;
}
