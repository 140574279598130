.intro-wrap {
  padding: 9.075vh 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 60px;
  max-width: 1280px;
}

.intro-title {
  color: #0D1242;
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}

.intro-list-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-items: stretch;
}

.intro-item-wrap {
  width: calc(33.33% - 16px - 16px);
  min-height: 335px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 24px;
  border-radius: 12px;
  border: 0.914px solid #0d1242;
  transition: all 0.3s;
}

.intro-item-wrap:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.intro-item-title {
  color: #0d1242;
  text-align: center;
  font-family: Inter;
  font-size: 18.286px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.429px;
}

.intro-item-desc {
  color: #0d1242;
  text-align: center;
  font-family: Inter;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.86px;
}

@media (max-width: 768px) {
  .intro-wrap {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 32px;
    padding: 88px 27.5px;
  }

  .intro-title {
    color: #0D1242;
    text-align: center;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 32px */
    letter-spacing: -0.96px;
  }

  .intro-list-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
  }

  .intro-item-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 24px;
    border-radius: 12px;
    border: 0.914px solid #0d1242;
  }

  .intro-item-title {
    padding-top: 4px;
    align-self: stretch;
    color: #0D1242;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
  }

  .intro-item-desc {
    color: #0d1242;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}
