.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 8px 0;
  transition: background 0.3s;
}

.header.scrolled {
  background: #0D1242;
}

.header-wrap {
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 8px;
}

.wallet-btn-wrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.wallet-btn {
  padding: 6px 16px;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 100px;
  cursor: pointer;
}

.white-paper-btn {
  color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
}

.white-paper-btn:hover,
.white-paper-btn:active {
  color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(255, 255, 255, 1);
}

.get-token-btn {
  color: #0D1242;
  background: rgba(255, 255, 255, 0.9);
}

.get-token-btn:hover,
.get-token-btn:active {
  background: rgba(255, 255, 255, 1);
}

@media screen and (max-width: 768px) {
  .header {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .header-wrap {
    padding:16px;
  }



  .wallet-btn {
    padding: 8px 16px;
    font-family: "Inter";
    font-size: 12px;
    line-height: 16px;
    font-style: normal;
    font-weight: 500;
    border-radius: 100px;
    cursor: pointer;
  }
}
