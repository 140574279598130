.feature-wrap {
  width: 100%;
  padding: 9.075vh 0;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 60px;
  max-width: 1280px;
}

.feature-title {
  color: #0D1242;
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px; /* 112.5% */
}

.feature-list-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-items: stretch;
}

.feature-item-wrap {
  width: calc(50% - 12px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap:24px;
  padding: 48px 40px;
  border-radius: 12px;
  border: 1px solid rgba(135, 205, 255, 0.6);

  background: linear-gradient(
    180deg,
    rgba(135, 169, 255, 0) 0%,
    rgba(135, 169, 255, 0.03) 100%
  );

  backdrop-filter: blur(5px);
  transition: all 0.3s;
}

.feature-item-wrap:hover {
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.feature-item-title {
  padding-top: 8px;
  align-self: stretch;
  color: #0D1242;
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 150% */
}

.feature-item-desc {
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.12px;
}

@media (max-width: 768px) {
  .feature-wrap {
    width: 100%;
    padding: 88px 27.5px;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 60px;
  }

  .feature-title {
    color: #0D1242;
    text-align: center;
    font-family: "Inter";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -0.96px;
  }

  .feature-list-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
  }

  .feature-item-wrap {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgba(135, 205, 255, 0.6);

    background: linear-gradient(
      180deg,
      rgba(135, 169, 255, 0) 0%,
      rgba(135, 169, 255, 0.03) 100%
    );

    backdrop-filter: blur(5px);
  }

  .feature-item-title {
    padding-top: 8px;
    align-self: stretch;
    color: #0D1242;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }

  .feature-item-desc {
    color: rgba(0, 0, 0, 0.6);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.12px;
  }
}
